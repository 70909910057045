html,
body {
  height: 100%;
  font-size: 18px;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Regular.eot');
  src: url('fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/Lato-Regular.woff2') format('woff2'),
    url('fonts/Lato-Regular.woff') format('woff'),
    url('fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-LightItalic.eot');
  src: url('fonts/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/Lato-LightItalic.woff2') format('woff2'),
    url('fonts/Lato-LightItalic.woff') format('woff'),
    url('fonts/Lato-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Bold.eot');
  src: url('fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/Lato-Bold.woff2') format('woff2'),
    url('fonts/Lato-Bold.woff') format('woff'),
    url('fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

:not(input) {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

#root {
  height: 100%;
}

.ui.button {
  text-transform: uppercase;
}

.ui.table thead tr th {
  text-transform: initial;
}

.ui.modals {
  justify-content: center !important;
  z-index: 1000;
}

.ui.inverted.modals {
  z-index: 999;
}

/* Custom scrollbars */

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  -webkit-appearance: auto !important;
}

::-webkit-scrollbar-button:start,
::-webkit-scrollbar-button:end {
  display: none !important;
}

/* To stench a Semantic UI Menu.Item */
.menu:has(> .item.menu-item_stretched) {
  flex-grow: 1;
}
